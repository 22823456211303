<template>
  <v-btn
    fab
    small
    text
    absolute
    right
    bottom
    @click="controls = !controls"
    class="behavior_book_control_button"
  >
    <v-icon> mdi-wrench </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ShowControlsToggle",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
  },
  computed: {
    controls: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_book_control_button {
  opacity: 0.3;
  margin-bottom: 20px;
  &:hover {
    opacity: 1;
  }
}
</style>
